import light from '@/theme/light';
import moment from 'moment';
import { nFormatter } from '@/util/format';
import { roundOffFilter } from '@/util/format';

export function sankeyChart(data) {
	const option = {
		color: light.color,
		title: {
			text: data.title || '',
			subtext: data.subtext || '',
			textStyle: {
				color: '#FFF',
			},
		},
		tooltip: {
			trigger: 'item',
			triggerOn: 'mousemove'
		},
		label: {
			color: '#FFF',
		},
		grid: {
			left: '16%',
		},
		series: [
			{
				type: 'sankey',
				right: 150.0,
				data: data.nodes,
				links: data.links,
				emphasis: {
					focus: 'adjacency'
				},
				levels: [

				],
				lineStyle: {
					color: 'gradient',
					curveness: 0.5
				}
			}
		]
	};
	return option;
}