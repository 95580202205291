<template lang='pug'>
ValidationObserver(v-slot='{ invalid, validated, passes, validate }')
	v-overlay(:value='show', opacity='0.8', z-index='6')
		v-progress-circular(indeterminate, color='primary')
	v-card.filter-wrap
		v-tabs.filter-tab(v-model='tab', color='#fff', hide-slider)
			v-tab {{ $t("charts.everyDay") }}
		v-tabs-items.mt-1.mb-5.filter-wrap(v-model='tab')
			v-tab-item
				v-card.pa-4.py-0.filter-wrap(height='60px')
					v-row
						v-col.px-3(cols='12', sm='8', md='6', lg='3')
							DaliyPicker(:tab='tab', @emitupdateDate='updateDate')
		v-card.pt-0.pa-4.pb-4.filter-wrap(v-resize='onResize')
			v-row.justify-end.exportBtnUpper
				v-col.pa-4(v-if='windowSize.x <= 599')
				v-col.text-right.pa-0(v-if='windowSize.x > 599')
					div(@click='showhelperText')
						ExportBtn(:exportResult='getExportResult', @emitexportData='exportXlsxFile')
						span.ml-auto.mr-7.helperText(:class='{ show: helperShow }') {{ $t("common.helpText") }}
			SingleSelectPromo(:currencyStatus='currencyStatus', @emitDomainValue='updateDomainValue')
			v-row.justify-center
				v-col(cols='12', sm='8', md='6', lg='3')
					v-btn.searchBtn.defult-btn-color(@click='passes(send)', dark, rounded, style='width: 100%; height: 55px') {{ $t("common.search") }}
			v-card.pa-4.filter-wrap.div-w(v-if='Object.keys(getExportResult).length !== 0')
				v-col.py-0.fixBar.searchDate.justify-center(cols='12', md='6', lg='5', @click='goSearch')
					v-row.py-1
						v-col.ml-5.mr-auto.py-1.px-3(cols='auto')
							span.sub-title {{ searchDates[0] }} ～ {{ searchDates[1] }}
						v-col.mr-2.py-1.px-3(cols='auto')
							v-img.researchImg(max-width='25px', :src='imgSrc')

			v-row.pa-4(v-if='table_inner_all.table_data !== undefined')
				v-col(cols='12')
					b {{ $t('charts.promoCompareS') }}
				v-col
					VueTabulator(v-model='table_inner_all.table_data' :options='table_inner_all.table_opt')
			v-row.pa-4(v-if='table_outer_all.table_data !== undefined')
				v-col(cols='12')
					b {{ $t('charts.promoCompareA') }}
				v-col
					VueTabulator(v-model='table_outer_all.table_data' :options='table_outer_all.table_opt')
			v-row.pa-4(v-if='table_outer_new.table_data !== undefined')
				v-col(cols='12')
					b {{ $t('charts.promoCompareN') }}
				v-col
					VueTabulator(v-model='table_outer_new.table_data' :options='table_outer_new.table_opt')
			v-row.pa-4(v-if='table_outer_old.table_data !== undefined')
				v-col(cols='12')
					b {{ $t('charts.promoCompareO') }}
				v-col
					VueTabulator(v-model='table_outer_old.table_data' :options='table_outer_old.table_opt')
			v-row
				v-col.chartWrap(cols='12', lg='12', xs='12', v-if='Object.keys(promoSankey).length !== 0')
					v-chart.chartMoveDown(v-bind:style="{height: 1000 + 'px !important' }" :option='promoSankey', ref='promoSankey', autoresize)
</template>
<style scoped>
@media (max-width: 1500px) {
	.dataSelectBox {
		left: 0%;
	}
}
</style>
<script>
import i18n from '@/locale';
import { errorLogger } from '@/util/logger';
import { exportXlsx } from '@/util/xlsx';
import { mapActions, mapGetters } from 'vuex';
import { getPC, getPE } from '@/api/Promotion';
import { sankeyChart } from '@/assets/echarts/ocmsCommon/sankey';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { TabulatorComponent } from 'vue-tabulator';
import {
	commaFormatter,
	nFormatter,
	trans,
	transData,
	toThousandslsFilter,
	rateOfExchange,
	roundFilter,
	roundOffFilter,
	rdPercent,
	rdPercentOff,
	rdPercentOn,
	roundDecimal,
	roundOff,
	getNaN,
} from '@/util/format';
import DaliyPicker from '@/components/DatePicker/DaliyPicker_ocms';
import ECharts from 'vue-echarts';
import ExportBtn from '@/components/ExportBtn';
import SingleSelectCurrency from '@/components/SingleSelectCurrency';
import SingleSelectPromo from '@/components/SingleSelectPromo';
import dateType from '@/assets/constant/dateType';
import promoType from '@/assets/constant/promoType';
import statusCode from '@/assets/constant/statusCode';
import statusMsg from '@/util/http';
import COLORS from '@/theme/color';

export default {
	components: {
		'v-chart': ECharts,
		'VueTabulator': TabulatorComponent,
		DaliyPicker,
		ExportBtn,
		SingleSelectCurrency,
		SingleSelectPromo,
		ValidationObserver,
		ValidationProvider,
	},
	data() {
		return {
			windowSize: {
				x: 0,
			},
			dialog: false,
			getExportResult: {},
			helperShow: false,
			currencyStatus: true,
			show: false,
			tab: null,
			loading: false,
			dates: [],
			searchDates: [],
			dateType: dateType.BRIEF_DATE,
			imgSrc: require('@/assets/image/research.png'),
			sankeyData: {
				nodes: [],
				links: [],
			},
			promoSankey: {},
			table_inner_all: {},
			table_outer_all: {},
			table_outer_new: {},
			table_outer_old: {},
		};
	},
	mounted() {
		this.initData();
	},
	methods: {
		...mapActions(['setDialogShow', 'setStatusDialog', 'setOperatorDefaultRouterTab', 'setOpenAutoSearch"']),
		async send() {
			this.allClean();
			this.show = !this.show;
			this.loading = !this.loading;
			try {
				const reqData = {
					currency: this.currency,
					startDate: this.$moment(this.dates[0]).format(dateType.DATE),
					endDate: this.$moment(this.dates[1]).format(dateType.DATE),
					domain: [this.value],
					promoTypeList: this.typeList,
					extendDays: 14,
					viewLangCode: localStorage.getItem('language'),
				};
				this.searchDates = [reqData.startDate, reqData.endDate];
				const res = await getPC(reqData);
				if (res.status === statusCode.STATUS_OK) {
					localStorage.setItem('currency', this.currency);
					const result = res.result.res;
					this.compare = result.ctx_promo_compare;
					this.promoProfile = result.ctx_promo_profile;
					this.promoRelation = result.ctx_promo_relation;
					this.promoGraphCircular(this.promoRelation, this.promoProfile);
					this.getExportResult = JSON.parse(JSON.stringify(result));

					const res_inner_all = result.ctx_promo_compare.map(e1 => {
						return {dim_promo_id: e1.dim_promo_id, ...e1.ctx_inner_all};
					});
					const res_outer_all = result.ctx_promo_compare.map(e1 => {
						return {dim_promo_id: e1.dim_promo_id, ...e1.ctx_outer_all};
					});
					const res_outer_new = result.ctx_promo_compare.filter(e1 => e1.ctx_outer_new.ttl_au > 0).map(e1 => {
						return {dim_promo_id: e1.dim_promo_id, ...e1.ctx_outer_new};
					});
					const res_outer_old = result.ctx_promo_compare.filter(e1 => e1.ctx_outer_old.ttl_au > 0).map(e1 => {
						return {dim_promo_id: e1.dim_promo_id, ...e1.ctx_outer_old};
					});

					const col_define_list_inner_all = [
						{col_name: 'rpt_term', col_key: 'rpt_term', color: ["#7094D6"], width: 150},
						{col_name: 'done_ratio', col_key: 'done_ratio', is_ratio: true, color: ["#7094D6"], width: 150},
						{col_name: 'ttl_join_count', col_key: 'join_count', color: [COLORS.amethyst[8]], width: 150},
						{col_name: 'avg_join_count', col_key: 'join_count', color: [COLORS.amethyst[5]], width: 150},
						{col_name: 'ttl_done_count', col_key: 'done_count', color: [COLORS.nephritis[8]], width: 150},
						{col_name: 'avg_done_count', col_key: 'done_count', color: [COLORS.nephritis[5]], width: 150},
						{col_name: 'ttl_bonus_out_amt', col_key: 'bonus_out_amt', color: [COLORS.orange[8]], width: 150},
						{col_name: 'avg_bonus_out_amt', col_key: 'bonus_out_amt', color: [COLORS.orange[5]], width: 150},
						{col_name: 'ttl_bonus_19_amt', col_key: 'bonus_19_amt', color: [COLORS.turquoise[8]], width: 150},
						{col_name: 'avg_bonus_19_amt', col_key: 'bonus_19_amt', color: [COLORS.turquoise[5]], width: 150},
						{col_name: 'ttl_bonus_20_amt', col_key: 'bonus_20_amt', color: [COLORS.sunflower[8]], width: 150},
						{col_name: 'avg_bonus_20_amt', col_key: 'bonus_20_amt', color: [COLORS.sunflower[5]], width: 150},
					];

					const col_define_list_outer_all = [
						{col_name: 'ttl_au', col_key: 'join_users', color: ["#7AB6D8"], width: 150},
						{col_name: 'influential', col_key: 'influential', is_ratio: true, color: ["#7094D6"], width: 150},
						{col_name: 'ttl_bonus_amt', col_key: 'bonus_amt', color: [COLORS.amethyst[8]], width: 150},
						{col_name: 'avgpu_bonus_amt', col_key: 'bonus_amt', color: [COLORS.amethyst[5]], width: 150},
						{col_name: 'ttl_dpst_amt', col_key: 'dpst_amt', color: [COLORS.nephritis[8]], width: 150},
						{col_name: 'avgpu_dpst_amt', col_key: 'dpst_amt', color: [COLORS.nephritis[5]], width: 150},
						{col_name: 'ttl_revenue_amt', col_key: 'revenue_amt', color: [COLORS.orange[8]], width: 150},
						{col_name: 'avgpu_revenue_amt', col_key: 'revenue_amt', color: [COLORS.orange[5]], width: 150},
						{col_name: 'ttl_apv_bet', col_key: 'apv_bet', color: [COLORS.turquoise[8]], width: 150},
						{col_name: 'avgpu_apv_bet', col_key: 'apv_bet', color: [COLORS.turquoise[5]], width: 150},
						{col_name: 'ttl_house_net_win', col_key: 'house_net_win', color: [COLORS.sunflower[8]], width: 150},
						{col_name: 'avgpu_house_net_win', col_key: 'house_net_win', color: [COLORS.sunflower[5]], width: 150},
					];

					const col_define_list_outer_new = [
						{col_name: 'ttl_au', col_key: 'join_users', color: ["#7AB6D8"]},
						{col_name: 'influential', col_key: 'influential', is_ratio: true, color: ["#7094D6"]},
						{col_name: 'af_dpst_stickiness', col_key: 'dpst_stickiness', is_ratio: true, color: [COLORS.orange[8]]},
						{col_name: 'af_ttl_no_promo_dpst_amt', col_key: 'no_promo_dpst_amt', color: [COLORS.nephritis[8]]},
						{col_name: 'af_avgpu_no_promo_dpst_amt', col_key: 'no_promo_dpst_amt', color: [COLORS.nephritis[5]]},
						{col_name: 'af_gm_stickiness', col_key: 'gm_stickiness', is_ratio: true, color: [COLORS.sunflower[8]]},
						{col_name: 'af_ttl_no_promo_bet', col_key: 'no_promo_bet', color: [COLORS.turquoise[8]]},
						{col_name: 'af_avgpu_no_promo_bet', col_key: 'no_promo_bet', color: [COLORS.turquoise[5]]},
					];

					const col_define_list_outer_old = [
						{col_name: 'ttl_au', col_key: 'join_users', color: ["#7AB6D8"]},
						{col_name: 'influential', col_key: 'influential', is_ratio: true, color: ["#7094D6"]},
						{col_name: 'af_dpst_stickiness', col_key: 'dpst_stickiness', has_bf: true, is_ratio: true, color: [COLORS.orange[8]]},
						{col_name: 'df_dpst_stickiness', col_key: 'dpst_stickiness', is_ratio: true, color: [COLORS.orange[5]]},
						{col_name: 'af_ttl_no_promo_dpst_amt', col_key: 'no_promo_dpst_amt', has_bf: true, color: [COLORS.nephritis[8]]},
						{col_name: 'df_ttl_no_promo_dpst_amt', col_key: 'no_promo_dpst_amt', color: [COLORS.nephritis[5]]},
						{col_name: 'af_avgpu_no_promo_dpst_amt', col_key: 'no_promo_dpst_amt', has_bf: true, color: [COLORS.turquoise[8]]},
						{col_name: 'df_avgpu_no_promo_dpst_amt', col_key: 'no_promo_dpst_amt', color: [COLORS.turquoise[5]]},
					];
					function on_title(p_column){
						let prefix_key = '';
						if(p_column.col_name.includes('ttl_')) prefix_key = 'modifier.prefix_ttl';
						if(p_column.col_name.includes('avg_')) prefix_key = 'modifier.prefix_avg';
						if(p_column.col_name.includes('avgpu_')) prefix_key = 'modifier.prefix_avgpu';

						let surfix_key = '';
						if(p_column.col_name.startsWith('bf_')) surfix_key = 'modifier.promo_bf';
						if(p_column.col_name.startsWith('af_')) surfix_key = 'modifier.promo_af';
						if(p_column.col_name.startsWith('df_')) surfix_key = 'modifier.promo_df';

						return i18n.t(prefix_key) + i18n.t(`metric.${p_column.col_key}`) + i18n.t(surfix_key);
					}

					function on_key_cell(p_ctx_profile, p_key_col_name, p_row_id){
						const row_profile = p_ctx_profile.filter(e_1 => e_1[p_key_col_name] === p_row_id)[0];
						return `${i18n.t('metric.dim_promo_id')}: ${row_profile.dim_promo_id} \n` +
							`${i18n.t('charts.promoName')}: ${row_profile.promo_brief_name} \n` +
							`${i18n.t('charts.promoType')}: ${row_profile.promo_type_name} \n` +
							`${i18n.t('charts.promoStatus')}: ${row_profile.promo_status} \n` +
							`${i18n.t('charts.promoStart')}: ${row_profile.promo_start_date} \n` +
							`${i18n.t('charts.promoEnd')}: ${row_profile.promo_end_date} \n` +
							`${i18n.t('metric.enable_game_kind_list')}: ${row_profile.enable_game_kind_list} \n`
						;
					}

					function on_cell(p_row_data, p_cell, p_column){

						if(!p_column.has_bf)
							return;

						let prefix_key = '';
						if(p_column.col_name.includes('ttl_')) prefix_key = 'modifier.prefix_ttl';
						if(p_column.col_name.includes('avg_')) prefix_key = 'modifier.prefix_avg';
						if(p_column.col_name.includes('avgpu_')) prefix_key = 'modifier.prefix_avgpu';

						const fn_formatter = (p_column.is_ratio ? rdPercentOff : roundOffFilter);
						const title_1 = i18n.t(prefix_key) + i18n.t(`metric.${p_column.col_key}`) + i18n.t('modifier.promo_bf');
						const title_2 = i18n.t(prefix_key) + i18n.t(`metric.${p_column.col_key}`) + i18n.t('modifier.promo_af');
						return `${title_1}: ${fn_formatter(p_row_data[p_column.col_name.replace('af_', 'bf_')])}\n${title_2}: ${fn_formatter(p_cell.getValue())}`;
					}

					this.table_inner_all = this.gen_table(res_inner_all, this.promoProfile, col_define_list_inner_all, 'dim_promo_id', on_title, on_key_cell, on_cell);
					this.table_outer_all = this.gen_table(res_outer_all, this.promoProfile, col_define_list_outer_all, 'dim_promo_id', on_title, on_key_cell, on_cell);
					this.table_outer_new = this.gen_table(res_outer_new, this.promoProfile, col_define_list_outer_new, 'dim_promo_id', on_title, on_key_cell, on_cell);
					this.table_outer_old = this.gen_table(res_outer_old, this.promoProfile, col_define_list_outer_old, 'dim_promo_id', on_title, on_key_cell, on_cell);
				}
			} catch (e) {
				errorLogger(e);
				let apiError = this.$t('error.apiError');
				this.errorDialogStatus(apiError);
			}
			this.show = !this.show;
			this.loading = !this.loading;
		},
		async promoGraphCircular(res, res2) {
			res.sort((a, b) => (a.dim_promo_id_1st > b.dim_promo_id_1st ? 1 : -1));
			const links = [];
			let nodes = [], categories = [];
			if(!res.length) {
				this.promoSankey = {};
				return;
			}
			for(let i of res) {
				links.push({
					'source': i.dim_promo_id_1st.toString(),
					'target': i.dim_promo_id_2nd.toString()+ ' ',
					'value': i.cross_users
				});
				categories.push(i.dim_promo_id_1st.toString());
				categories.push(i.dim_promo_id_2nd.toString()+ ' ');
			}
			categories = Array.from(new Set(categories));
			for(let i of categories) {
				nodes.push({'name': i});
			}
			this.promoSankey = sankeyChart({
					title: this.$t('charts.promoRelation'),
					links,
					nodes
			});
			this.promoSankey.tooltip.formatter = (params) => {
				let profile = {};
				for(let i of res2) {
					if(i.dim_promo_id == params.data.name) {
						profile = i;
					}
				}
				if(params.dataType === 'edge') {
					return `${params.data.source} -> ${params.data.target}<br> ${i18n.t('charts.crossUsers')}: ${params.data.value}`;
				}
				return `${i18n.t('charts.promoName')}: ${profile.promo_brief_name} <br>${i18n.t('charts.promoType')}: ${profile.promo_type_name} <br>${i18n.t('charts.promoStatus')}: ${profile.promo_status} <br>${i18n.t('charts.promoStart')}: ${profile.promo_start_date} <br>${i18n.t('charts.promoEnd')}: ${profile.promo_end_date}`;
			};
		},

		gen_table(
			ctx_data,
			ctx_profile,
			p_col_define_list,
			p_key_col_name,
			pfn_on_title,
			pfn_on_key_cell,
			pfn_on_cell
		){
			let table_data = [];
			for(const e_1 of ctx_data){
				const row_data = {};
				row_data[p_key_col_name] = e_1[p_key_col_name];
				p_col_define_list.forEach((e_2) => {
					row_data[e_2.col_name] = e_1[e_2.col_name];
				});
				table_data.push(row_data);
			}

			let col_formatter_list = [
				{title: this.$t(`metric.${p_key_col_name}`), field: p_key_col_name, headerHozAlign: 'center', hozAlign: 'center', frozen: true, width: 120},
			];
			p_col_define_list.forEach((e_1) => {
				col_formatter_list.push({
					title: pfn_on_title(e_1),
					field: e_1.col_name,
					hozAlign: 'left',
					formatter: "progress",
					formatterParams: {
						max: Math.max(...table_data.map(e_2 => e_2[e_1.col_name])),
						color: e_1.color,
						legend: function(value){return (e_1.is_ratio ? rdPercentOff(value) : roundOffFilter(value))},
						legendColor: '#FFF',
					},
					width: e_1.width,
				});
			});

			return {
				table_data: table_data,
				table_opt: {
					layout: 'fitColumns',
					pagination: 'local',
					paginationSize: 20,
					movableColumns: true,
					columns: col_formatter_list,
					tooltipGenerationMode: 'hover',
					tooltips: function(p_cell){
						const row_id = p_cell.getData()[p_key_col_name];
						const row_data = ctx_data.filter(e_1 => e_1[p_key_col_name] === row_id)[0];

						if(p_cell.getField() === p_key_col_name)
							return pfn_on_key_cell(ctx_profile, p_key_col_name, row_id);

						for(const e_1 of p_col_define_list){
							if(p_cell.getField() == e_1.col_name)
								return pfn_on_cell(row_data, p_cell, e_1);
						};
					}
				},
			};
		},
		allClean() {
			this.sankeyData = {
				nodes: [],
				links: [],
			};
			this.promoSankey = {};
			this.table_inner_all = {};
			this.table_outer_all = {};
			this.table_outer_new = {};
			this.table_outer_old = {};
		},
		async exportXlsxFile() {
			try {
				let result = this.getExportResult;
				await exportXlsx('d', this.searchDates, result);
			} catch (e) {
				// eslint-disable-next-line no-console
				console.log('e', e);
				let apiError = this.$t('error.apiError');
				this.errorDialogStatus(apiError);
			}
		},
		updateDomainValue(val, val2, val3, val4) {
			this.value = val;
			this.domainMap = val2;
			this.currency = val3;
			this.typeList = val4;
		},
		onResize() {
			this.windowSize = { x: window.innerWidth };
		},
		initData() {
			this.$emit('emitsavePath', this.$route.path);
		},
		closeDialog() {
			this.dialog = false;
		},
		showhelperText() {
			if (Object.keys(this.getExportResult).length === 0) {
				this.helperShow = true;
			}
		},
		errorDialogStatus(notifyMsg) {
			let errorTitle = this.$t('error.errorTitle');
			const errorMsg = {
				statusMessage: errorTitle,
				statusSrc: require('@/assets/image/error_icon.png'),
				statusText: notifyMsg,
			};
			let stringErrorMsg = JSON.stringify(errorMsg);
			this.setDialogShow(true);
			this.setStatusDialog(stringErrorMsg);
		},
		matchedIndexPin() {
			let operatorDefaultRouter = this.getOperatorDefaultRouter.split(',');
			if (this.$route.name === operatorDefaultRouter[0] && this.getOpenAutoSearch) {
				if (operatorDefaultRouter[1] === 'daily') {
					this.tab = 0;
				}
			}
		},
		goSearch() {
			$('body,html').animate({ scrollTop: 0 }, 800);
		},
		updateDate(val) {
			this.dates = val;
		},
	},
	watch: {
		tab: function () {
			$(function () {
				$('.fixBar').removeClass('dateFixBar');
				$(window).scroll(function () {
					let scrollVal = $(this).scrollTop();
					let div = $('.div-w');
					if (div.length) {
						let adscrtop = div.offset().top || { 'top': NaN }.top;
						if (scrollVal + 44 > adscrtop) {
							$('.fixBar').addClass('dateFixBar');
						} else {
							$('.fixBar').removeClass('dateFixBar');
						}
					}
				});
			});
		},
	},
	computed: {
		...mapGetters([
			'getDomain',
			'getDialogShow',
			'getStatusDialog',
			'getOperatorDefaultRouter',
			'getOpenAutoSearch',
		]),
	},
	created() {},
};
</script>